@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

#LilitaOne{
    font-family: 'Lilita One', cursive;
}

@layer base{
    :root{
        --color-neutral-50: theme('colors.neutral.50');
        --color-neutral-100: theme('colors.neutral.100');
        --color-neutral-200: theme('colors.neutral.200');
        --color-neutral-300: theme('colors.neutral.300');
        --color-neutral-400: theme('colors.neutral.400');
        --color-neutral-500: theme('colors.neutral.500');
        --color-neutral-600: theme('colors.neutral.600');
        --color-neutral-700: theme('colors.neutral.700');
        --color-neutral-800: theme('colors.neutral.800');
        --color-neutral-900: theme('colors.neutral.900');
    }
}

.theme-none{
    --color-primary-50: theme('colors.fuchsia.50');
    --color-primary-100: theme('colors.fuchsia.100');
    --color-primary-200: theme('colors.fuchsia.200');
    --color-primary-300: theme('colors.fuchsia.300');
    --color-primary-400: theme('colors.fuchsia.400');
    --color-primary-500: theme('colors.fuchsia.500');
    --color-primary-600: theme('colors.fuchsia.600');
    --color-primary-700: theme('colors.fuchsia.700');
    --color-primary-800: theme('colors.fuchsia.800');
    --color-primary-900: theme('colors.fuchsia.900');
}

.theme-green{
    --color-primary-50: theme('colors.green.50');
    --color-primary-100: theme('colors.green.100');
    --color-primary-200: theme('colors.green.200');
    --color-primary-300: theme('colors.green.300');
    --color-primary-400: theme('colors.green.400');
    --color-primary-500: theme('colors.green.500');
    --color-primary-600: theme('colors.green.600');
    --color-primary-700: theme('colors.green.700');
    --color-primary-800: theme('colors.green.800');
    --color-primary-900: theme('colors.green.900');
}

.theme-red{
    --color-primary-50: theme('colors.red.50');
    --color-primary-100: theme('colors.red.100');
    --color-primary-200: theme('colors.red.200');
    --color-primary-300: theme('colors.red.300');
    --color-primary-400: theme('colors.red.400');
    --color-primary-500: theme('colors.red.500');
    --color-primary-600: theme('colors.red.600');
    --color-primary-700: theme('colors.red.700');
    --color-primary-800: theme('colors.red.800');
    --color-primary-900: theme('colors.red.900');
}

.theme-purple{
    --color-primary-50: theme('colors.purple.50');
    --color-primary-100: theme('colors.purple.100');
    --color-primary-200: theme('colors.purple.200');
    --color-primary-300: theme('colors.purple.300');
    --color-primary-400: theme('colors.purple.400');
    --color-primary-500: theme('colors.purple.500');
    --color-primary-600: theme('colors.purple.600');
    --color-primary-700: theme('colors.purple.700');
    --color-primary-800: theme('colors.purple.800');
    --color-primary-900: theme('colors.purple.900');
}

.theme-blue{
    --color-primary-50: theme('colors.blue.50');
    --color-primary-100: theme('colors.blue.100');
    --color-primary-200: theme('colors.blue.200');
    --color-primary-300: theme('colors.blue.300');
    --color-primary-400: theme('colors.blue.400');
    --color-primary-500: theme('colors.blue.500');
    --color-primary-600: theme('colors.blue.600');
    --color-primary-700: theme('colors.blue.700');
    --color-primary-800: theme('colors.blue.800');
    --color-primary-900: theme('colors.blue.900');
}

.theme-yellow{
    --color-primary-50: theme('colors.yellow.50');
    --color-primary-100: theme('colors.yellow.100');
    --color-primary-200: theme('colors.yellow.200');
    --color-primary-300: theme('colors.yellow.300');
    --color-primary-400: theme('colors.yellow.400');
    --color-primary-500: theme('colors.yellow.500');
    --color-primary-600: theme('colors.yellow.600');
    --color-primary-700: theme('colors.yellow.700');
    --color-primary-800: theme('colors.yellow.800');
    --color-primary-900: theme('colors.yellow.900');
}

.theme-orange{
    --color-primary-50: theme('colors.orange.50');
    --color-primary-100: theme('colors.orange.100');
    --color-primary-200: theme('colors.orange.200');
    --color-primary-300: theme('colors.orange.300');
    --color-primary-400: theme('colors.orange.400');
    --color-primary-500: theme('colors.orange.500');
    --color-primary-600: theme('colors.orange.600');
    --color-primary-700: theme('colors.orange.700');
    --color-primary-800: theme('colors.orange.800');
    --color-primary-900: theme('colors.orange.900');
}

.theme-pink{
    --color-primary-50: theme('colors.pink.50');
    --color-primary-100: theme('colors.pink.100');
    --color-primary-200: theme('colors.pink.200');
    --color-primary-300: theme('colors.pink.300');
    --color-primary-400: theme('colors.pink.400');
    --color-primary-500: theme('colors.pink.500');
    --color-primary-600: theme('colors.pink.600');
    --color-primary-700: theme('colors.pink.700');
    --color-primary-800: theme('colors.pink.800');
    --color-primary-900: theme('colors.pink.900');
}

.theme-indigo{
    --color-primary-50: theme('colors.indigo.50');
    --color-primary-100: theme('colors.indigo.100');
    --color-primary-200: theme('colors.indigo.200');
    --color-primary-300: theme('colors.indigo.300');
    --color-primary-400: theme('colors.indigo.400');
    --color-primary-500: theme('colors.indigo.500');
    --color-primary-600: theme('colors.indigo.600');
    --color-primary-700: theme('colors.indigo.700');
    --color-primary-800: theme('colors.indigo.800');
    --color-primary-900: theme('colors.indigo.900');
}

.theme-teal{
    --color-primary-50: theme('colors.teal.50');
    --color-primary-100: theme('colors.teal.100');
    --color-primary-200: theme('colors.teal.200');
    --color-primary-300: theme('colors.teal.300');
    --color-primary-400: theme('colors.teal.400');
    --color-primary-500: theme('colors.teal.500');
    --color-primary-600: theme('colors.teal.600');
    --color-primary-700: theme('colors.teal.700');
    --color-primary-800: theme('colors.teal.800');
    --color-primary-900: theme('colors.teal.900');
}

.theme-cyan{
    --color-primary-50: theme('colors.cyan.50');
    --color-primary-100: theme('colors.cyan.100');
    --color-primary-200: theme('colors.cyan.200');
    --color-primary-300: theme('colors.cyan.300');
    --color-primary-400: theme('colors.cyan.400');
    --color-primary-500: theme('colors.cyan.500');
    --color-primary-600: theme('colors.cyan.600');
    --color-primary-700: theme('colors.cyan.700');
    --color-primary-800: theme('colors.cyan.800');
    --color-primary-900: theme('colors.cyan.900');
}

.theme-lime{
    --color-primary-50: theme('colors.lime.50');
    --color-primary-100: theme('colors.lime.100');
    --color-primary-200: theme('colors.lime.200');
    --color-primary-300: theme('colors.lime.300');
    --color-primary-400: theme('colors.lime.400');
    --color-primary-500: theme('colors.lime.500');
    --color-primary-600: theme('colors.lime.600');
    --color-primary-700: theme('colors.lime.700');
    --color-primary-800: theme('colors.lime.800');
    --color-primary-900: theme('colors.lime.900');
}

.theme-sky{
    --color-primary-50: theme('colors.sky.50');
    --color-primary-100: theme('colors.sky.100');
    --color-primary-200: theme('colors.sky.200');
    --color-primary-300: theme('colors.sky.300');
    --color-primary-400: theme('colors.sky.400');
    --color-primary-500: theme('colors.sky.500');
    --color-primary-600: theme('colors.sky.600');
    --color-primary-700: theme('colors.sky.700');
    --color-primary-800: theme('colors.sky.800');
    --color-primary-900: theme('colors.sky.900');
}

.theme-rose{
    --color-primary-50: theme('colors.rose.50');
    --color-primary-100: theme('colors.rose.100');
    --color-primary-200: theme('colors.rose.200');
    --color-primary-300: theme('colors.rose.300');
    --color-primary-400: theme('colors.rose.400');
    --color-primary-500: theme('colors.rose.500');
    --color-primary-600: theme('colors.rose.600');
    --color-primary-700: theme('colors.rose.700');
    --color-primary-800: theme('colors.rose.800');
    --color-primary-900: theme('colors.rose.900');
}


.theme-light{
    --neutralBg:theme('colors.white');
    ---onNeutralBg:var(--color-neutral-900);
    --primaryBg:var(--color-primary-50);
    --onPrimaryBg:var(--color-primary-900);
    --primary-50:var(--color-primary-50);
    --primary-100:var(--color-primary-100);
    --primary-200:var(--color-primary-200);
    --primary-300:var(--color-primary-300);
    --primary-400:var(--color-primary-400);
    --primary-500:var(--color-primary-500);
    --primary-600:var(--color-primary-600);
    --primary-700:var(--color-primary-700);
    --primary-800:var(--color-primary-800);
    --primary-900:var(--color-primary-900);
    --textColor:theme('colors.black');

}

.theme-dark{
    --neutralBg:var(--color-neutral-900);
    ---onNeutralBg:theme('colors.white');
    --primaryBg:var(--color-primary-900);
    --onPrimaryBg:var(--color-primary-50);
    --primary-50:var(--color-primary-900);
    --primary-100:var(--color-primary-800);
    --primary-200:var(--color-primary-700);
    --primary-300:var(--color-primary-600);
    --primary-400:var(--color-primary-500);
    --primary-500:var(--color-primary-400);
    --primary-600:var(--color-primary-300);
    --primary-700:var(--color-primary-200);
    --primary-800:var(--color-primary-100);
    --primary-900:var(--color-primary-50);
    --textColor:theme('colors.white');
}


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.gradient_1 {
    background-image: linear-gradient(to right, #9F70FD, #7F27FF);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline;
  }


body{

}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;

}

/* .dark-mode {
    background-color: #333; 
    color: #fff; 
} */

/* .dark-mode {
    --bg-color: #5B0888;
    --sec-color: var(--primary);
    --text-color: #fff; 
  }

  .dark .dark-mode {
    background-color: var(--bg-color);
    color: var(--text-color);
  } */

 